import React from "react";
export default function Footer() {
  return (
    <footer className="p-3 text-end bg-slate-400 bottom-0">
      <small>
        Developed by Remy.M.Ali
        <a
          href="https://github.com/remymali/ExpenseTracker"
          title="Expense Tracker"
          target="_blank"
          rel="noreferrer"
        >
          
        </a>
      </small>
    </footer>
  );
}
