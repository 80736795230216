import React from 'react';

const Header = () => {
  return (
    <header className="bg-gray-200 px-4 py-6 flex justify-center items-center"> {/* Adjust background color as needed */}
      <div className="text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-2">Expense Tracker</h1> {/* Adjust text color as needed */}
        <p className="text-gray-600">Track your income and expenses</p> {/* Optional subtitle */}
      </div>
    </header>
  );
};

export default Header;
