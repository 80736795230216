import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { Chart } from 'react-chartjs-2';
import 'chart.js/auto'; // Required for Chart.js v3
import '@fortawesome/fontawesome-free/css/all.min.css';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const CombinedExpensePage = () => {
  const { auth } = useContext(AuthContext);
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState('expense');
  const [expenses, setExpenses] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [totalBudget, setTotalBudget] = useState(1000); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://gamep.zorgo.xyz/api/expenses', {
        title,
        amount,
        date,
        category,
        description,
        type,
      }, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      console.log('Expense added successfully:', response.data);

      setTitle('');
      setAmount('');
      setDate('');
      setCategory('');
      setDescription('');

      fetchCombinedData();
      //fetchExpenses();
    } catch (error) {
      console.error('Error adding expense:', error.response ? error.response.data : error.message);
    }
  };
 const fetchCombinedData = async () => {
    try {
      const response = await axios.get('https://gamep.zorgo.xyz/api/combined', {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setExpenses(response.data.expenses);
      setIncomes(response.data.incomes);
      calculateTotalBudget(response.data.incomes);
    } catch (error) {
      console.error('Error fetching combined data:', error);
    }
  };
  const calculateTotalBudget = (incomes) => {
    const totalIncome = incomes.reduce((acc, income) => acc + parseFloat(income.amount), 0);
    setTotalBudget(totalIncome);
  };
  const fetchExpenses = async () => {
    try {
      const response = await axios.get('https://gamep.zorgo.xyz/api/expenses', {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setExpenses(response.data);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };
  useEffect(() => {
    fetchCombinedData();
  }, [auth.token]);
  useEffect(() => {
    fetchExpenses();
  }, [auth.token]);

  const calculateTotalSpent = () => {
    return expenses.reduce((acc, expense) => acc + parseFloat(expense.amount), 0);
  };
  //Delete
  const handleDelete = async (expenseIdToDelete) => {
    try {
      await axios.delete(`https://gamep.zorgo.xyz/api/expenses/${expenseIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const filteredExpenses = expenses.filter(expense => expense.id !== expenseIdToDelete);
      setExpenses(filteredExpenses);
      
    } catch (error) {
      console.error('Error deleting expense:', error);
    }
  };
//
// Export expenses to PDF
const exportToPdf = () => {
  const doc = new jsPDF();
  autoTable(doc, {
    head: [['Title', 'Amount', 'Date', 'Category', 'Description']],
    body: expenses.map(expense => [
      expense.title,
      expense.amount,
      new Date(expense.date).toLocaleDateString(),
      expense.category,
      expense.description,
    ]),
  });
  doc.save('expenses.pdf');
};

  const chartData = {
    labels: ['Remaining Budget', 'Total Spent'],
    datasets: [
      {
        label: 'Budget vs Spent',
        data: [totalBudget - calculateTotalSpent(), calculateTotalSpent()],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 p-4">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-center mb-8">Add New Expense</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex flex-col">
                <label htmlFor="title" className="mb-2 text-lg font-medium">Title:</label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="amount" className="mb-2 text-lg font-medium">Amount:</label>
                <input
                  type="number"
                  id="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="date" className="mb-2 text-lg font-medium">Date:</label>
                <input
                  type="date"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  placeholder="Select Date"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="category" className="mb-2 text-lg font-medium">Category:</label>
                <input
                  type="text"
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  placeholder="Enter Category"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="description" className="mb-2 text-lg font-medium">Description:</label>
                <input
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Description (optional)"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <button type="submit" className="w-full py-3 bg-blue-500 text-white text-lg font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Add Expense</button>
            </form>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-4">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-center mb-8">Expense List</h2>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2">Title</th>
                  <th className="py-2">Amount</th>
                  <th className="py-2">Date</th>
                  <th className="py-2">Category</th>
                  <th className="py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {expenses.map((expense) => (
                  <tr key={expense.id}>
                    <td className="py-2">{expense.title}</td>
                    <td className="py-2">{expense.amount}</td>
                    <td className="py-2">{new Date(expense.date).toLocaleDateString()}</td>
                    <td className="py-2">{expense.category}</td>
                    <td className="py-2 px-4 border-b border-gray-300 text-center"> {/* Added text-center class */}
                <i className="fa fa-fw fa-trash cursor-pointer text-red-600" onClick={() => handleDelete(expense.id)}></i> {/* Added cursor-pointer and text-red-600 classes */}
              </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-end mt-8">
        <div className="" style={{ width: '300px', marginTop: '-400px' }}>
          <h2 className="text-3xl font-bold text-center mb-8" >Set Total Budget</h2>
          <input
            type="number"
            value={totalBudget}
            onChange={(e) => setTotalBudget(e.target.value)}
            placeholder="Enter Total Budget"
            className="w-full px-4 py-2  border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div>
            <Chart type="doughnut" data={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinedExpensePage;
