import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Cookies from 'js-cookie'; // Import js-cookie library
import '../index.css';

const Login = () => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState('testuser');
  const [password, setPassword] = useState('password123');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://gamep.zorgo.xyz/api/auth/login', { username, password });
      const { token } = response.data;
      login(token); // Pass the token to the AuthContext

      // Save token in cookie
      Cookies.set('auth_token', token, { expires: 7 }); // Set expiry to 7 days
      console.log("token", token);
      navigate('/'); // Redirect to home page
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {/* Centering the container */}
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-md shadow-md">
        {/* Responsive container */}
        <h2 className="text-2xl font-bold text-center">Admin Panel</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-700 transition-colors duration-300"
          >
            Login
          </button>
        </form>

        {/* Added register link section */}
        <div className="text-center pt-4">
          <p className="text-sm text-gray-500">
            Don't have an account?{' '}
            <a href="/register" className="text-blue-500 hover:underline">
              Register Now
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
