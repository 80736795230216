import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from '../context/AuthContext';
import ExpenseForm from '../components/ExpenseForm';
import ExpenseList from '../components/ExpenseList';
import Login from '../components/Login';
import styled from 'styled-components';
import Header from '../components/Header';
import '../index.css'; 
import Footer from '../components/Footer';
import PdfCreatorButton from '../components/PdfCreator/PdfCreaterButton';
import Register from '../components/Register';
// import Transaction from './components/Transaction'; // Uncomment if needed

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
          <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<ProtectedRoutes />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

const ProtectedRoutes = () => {
  const { auth } = React.useContext(AuthContext);
  return (
    auth.isAuthenticated ? (
      <Dashboard />
    ) : (
      <Navigate to="/login" />
    )
  );
};

const Dashboard = () => (
<div>
    <Header />
    <div className='container'>
     
      
      
      <ExpenseList />
      <PdfCreatorButton />
    </div>
    <Footer/>
    </div>
);

const AppStyled = styled.div`
  
`;

export default App;
