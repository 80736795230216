import React from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

export default function PdfCreator(props) {
  const doc = new jsPDF();

  function exportTable() {
    autoTable(doc, { html: props.id });
    doc.save('export.pdf');
  }

  return (
    <button
      className="bg-gray-500 bg-gradient-to-r from-gray-500 to-gray-700 text-white font-bold m-4 px-4 py-2 rounded"
      onClick={exportTable}
    >
      Export PDF
    </button>
  );
}
